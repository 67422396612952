import domReady from '@roots/sage/client/dom-ready';
import jQuery from 'jquery';
import { Autoplay, Navigation, Pagination, Swiper } from 'swiper';
Swiper.use([Navigation, Autoplay, Pagination])


/**
 * Application entrypoint
 */
jQuery(($) => {

	// Ehealth filter functionality
	function toggleWfpFilter() {
		$(".wpfCheckboxHier").hide();

		$(".wfpDescription").on('click', function(){
			$(this).toggleClass("active");
			$(this).next(".wpfCheckboxHier").slideToggle(200);
		});
	}


	// dorpdown functionality
	function initPopups() {
		if ($('.popup--trigger').length > 0) {
			$('.popup--trigger, .popup--close').on('click', function (e) {
				// get data attibute popup
				const popup = $(this).data('popup');
				// get popup element with class popup--content and matching data attribute
				const popupElement = $(`.popup--content[data-popup="${popup}"]`);
				popupElement.fadeToggle(200);
			});
		}
	}

	function initDropdowns() {
		if ($('.dropdown--parent').length > 0) {
			$('.dropdown--parent').on('click', function (e) {
				const content = $(this).next('.dropdown--content');

				if (content.length > 0) {
					content.slideToggle(200);
				}
			});
		}
	}

	function initSublinkDropdowns() {
		if ($('.main_link_').length > 0) {
			$('.main_link_').on('click', function (e) {
				$(this).toggleClass('active');
				const content = $(this).parent().find('.sublink_dropdown_');

				if (content.length > 0) {
					content.slideToggle(200);
				}
			});
		}
	}


	// sublink children dropdown functionality for new header (02-05-2024)
	function initSublinkChildrenDropdowns() {
		if ($('.sublink_child_dropdown_trigger_').length > 0) {
			$('.sublink_child_dropdown_trigger_').on('click', function (e) {
				$(this).toggleClass('active');
				const leftTabLinks = $(this).next('.left-tab-links');
				const rightTabLinks = leftTabLinks.next('.right-tab-links');
				const octopusLink = rightTabLinks.next('.octopus-link');

				if (leftTabLinks || rightTabLinks || octopusLink) {
					leftTabLinks.slideToggle(200);
					rightTabLinks.slideToggle(200);
					octopusLink.slideToggle(200);
					leftTabLinks.css('display', 'flex');
					rightTabLinks.css('display', 'flex');
				}
			});
		}
	}

	function initLabelTabs() {
		if ($('.labeltabs--parent').length > 0) {
			$('.labeltabs--trigger').on('click', function (e) {
				const index = $(this).index();
				const parent = $(this).parent('.labeltabs--parent');
				const contentParent = parent.next('.labeltabs--content--parent');

				if (contentParent) {
					parent.find('.labeltabs--trigger').removeClass('active');
					$(this).addClass('active');
					const content = contentParent.find('.labeltabs--content');
					content.hide();
					content.eq(index).show();
				}
			});
		}
	}

	function initLabelTabsT() {
		if ($('.t-labeltabs--parent').length > 0) {
			$('.t-labeltabs--trigger').on('click', function (e) {
				const index = $(this).index();
				const parentWrap = $(this).parent('.t-labeltabs--parent--wrap');
				const parent = parentWrap.parent('.t-labeltabs--parent');
				const contentParent = parent.next('.t-labeltabs--content--parent');

				if (contentParent) {
					parent.find('.t-labeltabs--trigger').removeClass('active');
					$(this).addClass('active');
					const content = contentParent.find('.t-labeltabs--content');
					content.hide();
					content.eq(index).show();
				}
			});
		}
	}

	// init functions
	toggleWfpFilter();
	initLabelTabs();
	initLabelTabsT();
	initDropdowns();
	initSublinkDropdowns();
	initSublinkChildrenDropdowns();
	initPopups();


	// header menu functionality
	if ($('header.navbar_').length > 0) {
		$('.hamburger_icon, .non_desktop_menu_bg, .close_icon').on('click', function (e) {
			$('.non_desktop_menu').toggle();
		})
	}

	$(window).on('scroll', function () {
		var navbar = $('.navbar_'),
		scroll = $(window).scrollTop();
		var emptyDiv = $('.empty_div');


		if (scroll >= 50) {
			navbar.addClass('fixed top-0'),
			navbar.removeClass('relative'),
			emptyDiv.addClass('block'),
			emptyDiv.removeClass('hidden');
		}

		else {
			navbar.removeClass('fixed top-0'),
			navbar.addClass('relative'),
			emptyDiv.removeClass('block'),
			emptyDiv.addClass('hidden');
		}
	});

  //Logo slider
  if ($('.logo-slider').length > 0) {
		new Swiper('.logo-slider__swiper', {
			slidesPerView: 1,
			spaceBetween: 16,
			speed: 500,
			loop: true,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				nextEl: '.logo-slider-next-button',
				prevEl: '.logo-slider-prev-button',
			},
			breakpoints: {
				// when window width is >= 320px
				475: {
					slidesPerView: 2,
					spaceBetween: 16
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 16
				},
				// when window width is >= 480px
				920: {
					slidesPerView: 4,
					spaceBetween: 16
				},
				// when window width is >= 640px
				1160: {
					slidesPerView: 5,
					spaceBetween: 16
				}
			}
		})
  }

	//Link block slider
	if ($('.link-block-slider').length > 0) {
			new Swiper('.link-block-slider__swiper', {
				slidesPerView: 1,
				spaceBetween: 25,
				speed: 500,
				navigation: {
					nextEl: '.link-block-slider-next-button',
					prevEl: '.link-block-slider-prev-button',
				},
				pagination: {
					el: '.link-block-slider__swiper-pagination',
					clickable: true,
				},
				breakpoints: {
					610: {
						slidesPerView: 2,
						spaceBetween: 16
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 20
					}
				}
			})
	}


  if ($('.card-slider').length > 0) {
		new Swiper('.card-slider__swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 500,
			pagination: {
				el: '.card-slider__swiper-pagination',
				clickable: true,
			},
			navigation: {
				nextEl: '.card-slider-next-button',
				prevEl: '.card-slider-prev-button',
			},
			breakpoints: {
				610: {
					slidesPerView: 2,
					spaceBetween: 30
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 30
				}
			}
		})
  }

	if ($('.text-slider').length > 0) {
		var textSlider = new Swiper('.text-slider__swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 0,
			preventInteractionOnTransition: true,
			noSwiping: true,
			onlyExternal: true,
			pagination: {
				el: '.text-slider__swiper-pagination',
				clickable: true,
			},
			navigation: {
				nextEl: '.text-slider__swiper-button-next',
				prevEl: '.text-slider__swiper-button-prev',
			},
		})
	}

	//Handle nav title on click and pass index to swiper
	$('.text_slide_nav_title_').on('click', function (e) {
		textSlider.slideTo($(this).index());
	})

	//Image gallery swiper + thumbnail swiper
	var gallerySwiper = new Swiper(".image-gallery__swiper", {
		spaceBetween: 15,
		slidesPerView: 2,
		watchSlidesProgress: true,
		watchSlidesVisibility: true,
		slideToClickedSlide: true,
		breakpoints: {
			640: {
				slidesPerView: 3,
				spaceBetween: 16
			}
		},
	});

	var galleryThumbnailSwiper = new Swiper(".image-gallery-thumbnail__swiper", {
		spaceBetween: 0,
	});


	//Handle gallery image on click 
	$('.image_gallery_single_image_').on('click', function (e) {
		galleryThumbnailSwiper.slideTo($(this).index());
	})

	//Handle gallery image next button 
	$('.image-gallery-next-button').on('click', function (e) {
		galleryThumbnailSwiper.slideNext();
		gallerySwiper.slideNext();
	})

	//Handle gallery image prev button
	$('.image-gallery-prev-button').on('click', function (e) {
		galleryThumbnailSwiper.slidePrev();
		gallerySwiper.slidePrev();
	})


  if ($('.location-google-maps').length > 0) {
		var googleMapsSlider = new Swiper('.google-maps-locations__swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 0,
			preventInteractionOnTransition: true,
			noSwiping: true,
			onlyExternal: true,
		})
  }

	//Handle location name onClick 
	$('.location_name_').on('click', function (e) {
		googleMapsSlider.slideTo($(this).index());
		$('.location_name_').removeClass('active');
		$(this).addClass('active');
	})

	//Makes first name active 
	$(".location_name_").first().addClass('active')
	$(".location_name_").last().addClass('last')



	if ($('.review-slider').length > 0) {
		var reviewSlider = new Swiper('.review__swiper', {
			slidesPerView: 1,
			spaceBetween: 20,
			speed: 300,
			loop: true,
			navigation: {
				nextEl: '.review-next-button',
				prevEl: '.review-prev-button',
			},
			breakpoints: {
				1024: {
				slidesPerView: 2,
				spaceBetween: 75
				}
			},
		})
	}






  	// ajax function that handles filter on click of button with class filter--trigger
  	if ($('.filter--trigger').length > 0) {
		$('.filter--trigger').on('click', function (e) {
			// get parent taxonomyFilter form
			const taxonomyFilter = $(this).closest('.taxonomy-filter');
			// get data attribute posttype
			const posttype = taxonomyFilter.data('posttype');

			// action attribute of form
			const url = taxonomyFilter.attr('action');

			// get filterdata from form
			const filterData = taxonomyFilter.serialize();


			$.ajax({
				url: url,
				type: 'POST',
				data: {
				action: 'filter',
				posttype: posttype,
				filterData: filterData
				},
				error: function (response) {
				console.log('err', response);
				},
				// loading
				beforeSend: function () {
				$(`#response-${posttype}`).css('opacity', .2);
				},
				success: function (response) {
				// replace content of element with class filter--results with response
				$(`#pagination-${posttype}`).hide();
				$(`#response-${posttype}`).css('opacity', 1);
				$(`#response-${posttype}`).html(response);
				initDropdowns();
				}
			});
		});
  	}



	// set locnav height
	if ($('.navlinkwithicon').length > 0) {
		// if desktop
		if ($(window).width() > 1024) {
			let heighest = 0;
			$('.navlinkwithicon').each(function () {
				if ($(this).height() > heighest) {
					heighest = $(this).height();
				}
			})

			$('.navlinkwithicon').height(heighest);
		}
	}
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
